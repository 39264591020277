import React, { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import * as Request from "superagent";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false,
      isReadySendEmail: false,
      isProcessing: false,
      showHideToast: false,
      name: "",
      email: "",
      phone: "",
      message: "",
      userMessage: "",
      errors: { firstName: "", lastName: "", email: "", message: "" },
    };
    this.OnSendEmailClicked = this.OnSendEmailClicked.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async OnSendEmailClicked() {
    if (this.state.isReadySendEmail) {
      await this.setState({ isReadySendEmail: false, isProcessing: true });
      Request.post("/api/email/sendemail")
        .send(
          JSON.stringify({
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
          })
        )
        .set("Content-Type", "application/json")
        .end((err, res) => {
          this.setState({ isReadySendEmail: true, isProcessing: false });
          if (err) {
            this.setState({
              userMessage:
                "Oops, an error occurred. Please try again in 5 minutes..",
            });
          } else {
            this.setState({ name: "", email: "", phone: "", message: "" });
            this.setState({
              userMessage:
                "Thanks for contacting us, we will be in touch with you shortly.",
            });
          }
        });
    }
  }
  async handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let _value = "";

    switch (name) {
      case "name":
        _value = value.slice(0, 300);
        errors.name = _value.length !== 0 ? "" : "Pleae enter your name";
        if (errors.name.length === 0) {
          await this.setState({ name: _value });
        } else {
          await this.setState({ name: "" });
        }
        break;
      case "email":
        errors.email =
          value.length !== 0 ? "" : "Pleae enter your email address";
        if (errors.email.length === 0) {
          await this.setState({ email: value });
        } else {
          await this.setState({ email: "" });
        }
        break;
      case "phone":
        _value = value.slice(0, 300);
        errors.phone =
          _value.length !== 0 ? "" : "Pleae enter your phone number";
        if (errors.phone.length === 0) {
          await this.setState({ phone: _value });
        } else {
          await this.setState({ phone: "" });
        }
        break;
      case "message":
        _value = value.slice(0, 3000);
        errors.message = _value.length !== 0 ? "" : "Pleae enter your message";
        if (errors.message.length === 0) {
          await this.setState({ message: _value });
        } else {
          await this.setState({ message: "" });
        }
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
    let _isReadySendEmail =
      this.state.name.length > 0 &&
      this.state.email.length > 0 &&
      this.state.phone.length > 0 &&
      this.state.message.length > 0;
    this.setState({ isReadySendEmail: _isReadySendEmail });
  }
  render() {
    return (
      <section
        className="elementor-element elementor-element-9a6c678 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
        data-id="9a6c678"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-element elementor-element-d7d3994 elementor-column elementor-col-100 elementor-top-column"
              data-id="d7d3994"
              data-element_type="column"
              data-settings='{"background_background":"classic","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_infinite":"false"}'
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-b9a3450 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="b9a3450"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_infinite":"true","avante_ext_infinite_animation":"if_scale","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="90"
                          height="90"
                          src="upload/purple_bubble.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-36014db elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="36014db"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_infinite":"true","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_animation":"if_bounce","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="90"
                          height="90"
                          src="upload/purple_bubble.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-45db939 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="45db939"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_infinite":"true","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_animation":"if_bounce","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="70"
                          height="70"
                          src="upload/orange_bubble-1.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-8fe9ddf elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="8fe9ddf"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":{"unit":"px","size":200,"sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":-360,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="150"
                          height="120"
                          src="upload/orange_triangle2.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-18d063a elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="18d063a"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":{"unit":"px","size":"","sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":-360,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":70,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="150"
                          height="120"
                          src="upload/orange_triangle2.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-ca097ab elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="ca097ab"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatex":{"unit":"px","size":300,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":60,"sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":360,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":360,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="190"
                          height="150"
                          src="upload/orange_triangle-1.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-ad1748f elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="ad1748f"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatex":{"unit":"px","size":-200,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":60,"sizes":[]},"_position":"absolute","avante_ext_scrollme_smoothness":{"unit":"px","size":60,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":-360,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="190"
                          height="150"
                          src="upload/orange_triangle-1.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-ceb5275 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="ceb5275"
                    data-element_type="widget"
                    data-settings='{"_position":"absolute","avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatex":{"unit":"px","size":-100,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":-50,"sizes":[]},"avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="90"
                          height="90"
                          src="upload/purple_bubble.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-fecfb73 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="fecfb73"
                    data-element_type="widget"
                    data-settings='{"_position":"absolute","avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatex":{"unit":"px","size":-100,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":-50,"sizes":[]},"avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="90"
                          height="90"
                          src="upload/purple_bubble.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-fa1c2b9 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image"
                    data-id="fa1c2b9"
                    data-element_type="widget"
                    data-settings='{"_position":"absolute","avante_ext_is_scrollme":"true","avante_ext_scrollme_rotatey":{"unit":"px","size":360,"sizes":[]},"avante_ext_scrollme_translatex":{"unit":"px","size":-200,"sizes":[]},"avante_ext_scrollme_translatey":{"unit":"px","size":-70,"sizes":[]},"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":{"unit":"px","size":30,"sizes":[]},"avante_ext_scrollme_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_scalez":{"unit":"px","size":1,"sizes":[]},"avante_ext_scrollme_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_scrollme_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="70"
                          height="70"
                          src="upload/orange_bubble-1.png"
                          className="attachment-large size-large"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-c76081f elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                    data-id="c76081f"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        How can we help?
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-787274d elementor-widget elementor-widget-text-editor"
                    data-id="787274d"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-text-editor elementor-clearfix">
                        <div className="lqd-lines split-unit lqd-unit-animation-done">
                          <p className="p1">
                            Please complete the details below and then click on
                            Submit and we&#8217;ll be in contact
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-ba51080 elementor-widget elementor-widget-shortcode"
                    data-id="ba51080"
                    data-element_type="widget"
                    data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.8,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                    data-widget_type="shortcode.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-shortcode">
                        <div
                          role="form"
                          className="wpcf7"
                          id="wpcf7-f5-p5879-o1"
                          lang="en-US"
                          dir="ltr"
                        >
                          <div className="screen-reader-response"></div>

                          <div className="quform-elements">
                            <div className="quform-element">
                              <br />
                              <span className="wpcf7-form-control-wrap your-name">
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  size="40"
                                  className="input1"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Name*"
                                  value={this.state.name}
                                  onChange={this.handleChange}
                                />
                              </span>
                            </div>
                            <div className="quform-element">
                              <br />
                              <span className="wpcf7-form-control-wrap your-email">
                                <input
                                  id="email"
                                  type="text"
                                  name="email"
                                  size="40"
                                  className="input1"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Email*"
                                  value={this.state.email}
                                  onChange={this.handleChange}
                                />
                              </span>
                            </div>
                            <div className="quform-element">
                              <br />
                              <span className="wpcf7-form-control-wrap your-email">
                                <input
                                  id="phone"
                                  type="text"
                                  name="phone"
                                  size="40"
                                  className="input1"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Phone*"
                                  value={this.state.phone}
                                  onChange={this.handleChange}
                                />
                              </span>
                            </div>
                            <div className="quform-element">
                              <br />
                              <span className="wpcf7-form-control-wrap your-message">
                                <textarea
                                  id="message"
                                  name="message"
                                  cols="40"
                                  rows="5"
                                  className="input1"
                                  aria-invalid="false"
                                  placeholder="Message*"
                                  value={this.state.message}
                                  onChange={this.handleChange}
                                ></textarea>
                              </span>
                            </div>

                            <div className="quform-submit">
                              <div className="quform-submit-inner">
                                <Button
                                  onClick={this.OnSendEmailClicked}
                                  disabled={!this.state.isReadySendEmail}
                                  type="submit"
                                  className="prolink-contact-us-submit-button submit-button"
                                >
                                  {this.state.isProcessing ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : null}
                                  <span>Send</span>
                                </Button>
                                <div className="prolink-contact-us-user-message">
                                  {this.state.userMessage}
                                </div>
                              </div>
                              <div className="quform-loading-wrap">
                                <span className="quform-loading"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUs;
