import React, { Component } from "react";
import "../custom.css";
import { SectionContainer } from "../components/SectionContainer";

export default class Home extends Component {
  static displayName = Home.name;

  render() {
    const introDiv = document.getElementById("introDiv");
    if (introDiv) {
      introDiv.classList.add("hide");
    }
    const homeDiv = document.getElementById("homeDiv");
    if (homeDiv) {
      homeDiv.classList.remove("hide");
    }

    return <SectionContainer></SectionContainer>;
  }
}
