import React, { Component } from "react";
import "../custom.css";

export default class Intro extends Component {
  static displayName = Intro.name;

  render() {
    const homeDiv = document.getElementById("homeDiv");
    if (homeDiv) {
      homeDiv.classList.add("hide");
    }
    const introDiv = document.getElementById("introDiv");
    if (introDiv) {
      introDiv.classList.remove("hide");
    }
    return <div></div>;
  }
}
